import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Alert from "components/Alert";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "alert"
    }}>{`Alert`}</h1>

    <Playground __position={0} __code={'<Alert color=\"primary\">This is a primary alert!</Alert>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Alert color="primary" mdxType="Alert">
    This is a primary alert!
  </Alert>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/alerts/" target="_blank">Reactstrap Alert</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      